const contact = {
    title: 'Kontakt',
    name: 'Imię',
    email: 'Email',
    message: 'Wiadomość',
    send: 'Wyślij',
    description: "Chcesz mnie zatrudnić ? Potrzebujesz strony internetowej dla Twojego biznesu? Napisz!",
    platforms: "Napisz do mnie lub znajdź na:", 
    siteBy: '',
}

export default contact
