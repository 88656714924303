const about = {
    title: 'About Me',
        description: `	Technology is an astonishing thing still you can't forget who it's made for. And it is made for people. `,
        firstParagraph: `The digital revolution changed the world in almost every area and will continue to do so. There is no escape from change. I help people combine their business with technology to make their ideas come true. I've been interested in programming for a decade now! During this time, I was involved in various projects.
        Technologies such as Python, React, Node.js, WordPress were really helpful along the way.`,
        secondParagraph: `I graduated in Computer Science from the University of Technology in Poznan. Currently, I provide substantive support to several law firms and undertake many additional orders for web applications or websites as a freelancer.`,
        thirdParagraph: `  I am passionate about law, new technologies, and sharing knowledge. 
        I am sure that education is the most important in the world today and sharing it is everyone's responsibility to make world a better place. 
        This is one of the reason why during my free time I read a lot of books.
        I am also creator of the podcast and a sport lover. Our brain functions better if body is healthy ;) `,
        fourthParagraph: `In my career, I worked with clients from all around the world, both for large corporations but also for small, self-organized teams. 
        If you think we could benefit from each other, please do not hesitate to contact me!`
    }   


export default about
