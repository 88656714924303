const about = {
    title: 'O mnie',
        description: `Technologia jest niesamowita, ale nie można zapomnieć, dla kogo jest ona tworzona. A tworzona jest dla ludzi. `,
        firstParagraph: `Rewolucja cyfrowa zmieniła świat w niemal każdej dziedzinie i będzie to robić nadal. Nie ma ucieczki od zmian. Pomagam ludziom łączyć ich biznes z technologią, tak by idee będące niegdyś wyłącznie wyobrażeniem stały się rzeczywistością. Programowaniem interesuję się już od dekady. W tym czasie byłem zaangażowany w różne projekty. Technologie takie jak Python, React, Node.js, WordPress pomagały mi w ich realizacji.`,
        secondParagraph: `Ukończyłem Informatykę na Politechnice Poznańskiej. Obecnie udzielam wsparcia kilku kancelariom prawnym, a jako freelancer podejmuję się wielu dodatkowych zleceń tworząc aplikacje  i strony internetowe.`,
        thirdParagraph: `  Pasjonuje mnie informatyka i prawo, a najbardziej miejsce styku tych dwóch światów.
        Jestem przekonany, że edukacja i wiedza jest dziś najważniejszą wartością, a dzielenie się nią jest obowiązkiem każdego, kto chce uczynić nasz świat lepszym miejscem. 
         Moje przekonanie sprawia, że wolne chwilę poświęcam czytaniu wielu książek.
        Jestem także twórcą podcastu i miłośnikiem sportu. Nasze mózgi funkcjonuje lepiej, gdy ciało jest zdrowe ;)`,
        fourthParagraph: `W swojej karierze współpracowałem z ludźmi z całego świata, pracowałem zarówno dla korporacji, jak i małych, samo-organizujących się zespołów.
        Jeśli uważasz, że moglibyśmy sobie nawzajem pomóc nie wahaj się ze mną skontaktować!`
    }   


export default about
