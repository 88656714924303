const contact = {
    title: 'Contact',
    name: 'Name',
    email: 'Email',
    message: 'Message',
    send: 'Send',
    description: "Want to hire me? Let's talk about your website! ", 
    platforms: "Find me on the other platforms:", 
    siteBy: '',
}

export default contact
