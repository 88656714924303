import homepage from './homepage'
import blog from './blog'
import about from './about'
import contact from './contact'
import error404 from './error404'
import project from './project'

const pl = {
    homepage,
    about,
    blog,
    contact,
    error404,
    project,
}

export default pl
